import { useSubscription } from ".";
import CircleAvatar from "../components/atoms/CircleAvatar";
import "../components/molecules/Modal/LogoutModal/logout-modal.scss";

import { useSelector } from "react-redux";

const SubscriptionBanner = () => {
  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;

  const { isSubscriptionExpired, isShow, setIsShow } = useSubscription();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  if (!isSubscriptionExpired) return null;

  const trial_data_string = localStorage.getItem("trial_info");
  let trial_data;

  if (trial_data_string !== null) {
    // Only parse if the string is not null
    trial_data = JSON.parse(trial_data_string);
  }

  return (
    <div
      className={`modal ${isSubscriptionExpired && isShow ? "active" : ""}`}
      style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content">
        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2">
            <CircleAvatar
              name={userInfo?.name}
              size="90px"
              fontSize="25px"
              color="#F4F4F4"
              borderRadius="12px"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            />
            <p className="modal-heading mb-0 mt-2">
              {/* Your {trial_data?.is_trial ? "Free Trial" : "Subscription"} has
              expired. */}

              {trial_data?.is_trial
                ? "Upgrade Your Subscription"
                : "Renew Your Subscription"}
            </p>
          </div>

          {trial_data?.is_trial && (
            <p
              className="modal-description mt-2"
              style={{ textAlign: "center" }}
            >
              Your {userInfo?.subs_period_count} {userInfo?.subs_timespan} Free
              Trail has Expired. <br />
            </p>
          )}
          <p
            className={`modal-description ${
              trial_data?.is_trial ? "mt-0" : "mt-2"
            }`}
            style={{ textAlign: "center" }}
          >
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. */}
            {trial_data?.is_trial
              ? `Your Free Trial has expired. To continue enjoying our services, please upgrade your subscription
`
              : "Your subscription has expired. To continue enjoying our services, please renew your subscription"}
          </p>
          <div className="d-flex w-100 mt-3">
            <button
              className="btn btn-outline-dark"
              onClick={() => setIsShow(false)}
              style={{ flex: "1 0 45%" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mt-0"
              onClick={() => window.open(upgrade_link, "_blank")}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              {trial_data?.is_trial ? "Upgrade Now" : "Renew Now"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBanner;
