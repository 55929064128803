import React, { useState } from "react";
import Brand from "../../../assets/brand/PRIMEALPHA.png";
import LogoutModal from "../../molecules/Modal/LogoutModal/index";
import styles from "./navbar.module.scss";

import { useCookies } from "react-cookie";
import CircleAvatar from "../../atoms/CircleAvatar";
import Search from "../../atoms/Search";

import { Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthenticated } from "../../../hooks/useAuthenticated";
import Notification from "./Notification/index";

interface NavbarProps {
  searchRequired?: boolean;
  dataShow?: boolean;
  isActive?: boolean;
  setIsActive?: any;
  toggleActive?: any;
  sideBarRequired?: any;
  navbarRef?: any;
}

const Navbar: React.FC<NavbarProps> = ({
  searchRequired,
  dataShow = true,
  isActive,
  setIsActive,
  toggleActive,
  sideBarRequired = true,
  navbarRef,
}) => {
  const [cookies] = useCookies(["un"]);
  const navigate = useNavigate();
  // Profile Menu ------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProfileMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const auth = useAuthenticated();

  const toggleActiveClass = () => {
    toggleActive();
  };
  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);

  return (
    <div className={styles.navbar_container}>
      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      <nav className={styles.navbar}>
        <div className={styles.right}>
          {sideBarRequired && auth && (
            <div
              className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
              onClick={toggleActiveClass}
              ref={navbarRef}
            >
              <span className={styles.bar}></span>
              <span className={styles.bar}></span>
              <span className={styles.bar}></span>
            </div>
          )}

          <div
            className={styles.navbar_icon}
            onClick={() => navigate("/dashboard")}
          >
            <img src={Brand} height="45px" alt="Logo" />
          </div>
        </div>

        {searchRequired && auth && (
          <div className={styles.mid}>
            <Search onSearchEmit={() => {}} />
          </div>
        )}

        {dataShow && auth && (
          <div className={styles.left_side}>
            {/* <i
              className="fi fi-rr-bell cursor-pointer"
              onClick={() => navigate("/dashboard/requests")}
            ></i> */}
            <Notification />
            <div onClick={handleProfileMenu}>
              <CircleAvatar
                color="#3D9BE9"
                iconColor="#F4F4F4"
                name={userInfo?.name}
              />
            </div>
          </div>
        )}

        {dataShow && auth && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                width: "200px",
                borderRadius: "16px",
                marginTop: "20px", // Add margin-top to create space
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
                fontWeight: "500px !important",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/dashboard/edit");
                setAnchorEl(null);
              }}
            >
              Edit Profile
            </MenuItem>

            <MenuItem
              onClick={() => {
                navigate("/dashboard/edit/change-password");
                setAnchorEl(null);
              }}
            >
              Password & 2FA
            </MenuItem>

            <MenuItem
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setAnchorEl(null);
              }}
            >
              <span style={{ color: "#BE0F00" }}>Logout</span>
            </MenuItem>
          </Menu>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
