import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { deleteData, postData } from "../../../services";
import { useSubscription } from "../../../SubscriptionContext";
import SavedBlueIcon from "../../Icons/SavedBlueIcon";
import SavedIcon from "../../Icons/SavedIcon";
import style from "./save_component.module.scss";

const SaveComponent = ({ type, id, saved_id, btnType }: any) => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);
  const [savedId, setSavedId] = useState<string>(saved_id);

  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  useEffect(() => {
    setSavedId(saved_id);
    setIsSaved(saved_id && saved_id !== "00000000-0000-0000-0000-000000000000");
  }, [saved_id]);

  const handleSave = async () => {
    // dispatch(setLoading(true));
    const res = await postData({
      endpoint: "SavedList/addTOSavedList",
      data: {
        map_id: id,
        map_type: type,
      },
      token: cookies.t,
    });
    if (res) {
      // dispatch(setLoading(false));
      setSavedId(res?.data?.data?.id); // assuming the response has a saved_id field
      setIsSaved(true);
    }
  };

  const handleRemove = async () => {
    const res = await deleteData({
      endpoint: "SavedList/removeFromList",
      params: {
        id: savedId,
      },
      token: cookies.t,
    });

    if (res) {
      setSavedId("");
      setIsSaved(false);
    }
  };

  const handleClick = (e: any) => {
    if (isSubscriptionExpired) {
      handleUserClick(e);
      return;
    }
    if (isSaved) {
      handleRemove();
    } else {
      handleSave();
    }
  };
  return (
    <button
      key={id}
      onClick={handleClick}
      style={{
        cursor: "pointer",
        color: btnType === "btn-outline-dark" ? "black" : "",
      }}
      className={`${style.save} ${
        isSaved ? style.clicked : style.not_clicked
      } ${btnType === "btn-outline-dark" && "btn-outline-dark"}`}
    >
      {isSaved ? (
        <i className={`d-flex`} key={id}>
          <SavedBlueIcon />
        </i>
      ) : (
        <i className="d-flex" key={id}>
          <SavedIcon
            className={
              type === "company" || type === "Product"
                ? style.saved_company_icon_container
                : ""
            }
          />
        </i>
      )}
      {isSaved ? " Saved" : " Save"}
    </button>
  );
};

export default SaveComponent;
