import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSubscription } from "../../../../SubscriptionContext";
import { roles } from "../../../../helpers/roles";
import { getData } from "../../../../services";
import { loaderData } from "../../../../store/slices/loaderSlice";
import DotsIcon from "../../../Icons/Dots";
import RightUpArrow from "../../../Icons/RightUpArrow";
import CircularDropdown from "../../../atoms/CircularDropdown";
import PeopleProfileCard from "../../../atoms/PeopleProfileCard";
import TypeDiv from "../../../atoms/TypeDiv";
import Breadcrumb from "../../../molecules/Breadcrumb";
import SaveComponent from "../../../molecules/SaveComponent";
import ReportForm from "../../../organisms/forms/ReportForm";
import RequestInfoForm from "../../../organisms/forms/RequestInfoForm";
import Shimmer from "../Company/Shimmer/shimmer";
import Data from "./Data";
import Team from "./components/Team/Team";
import "./people.scss";

const People = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navigationType } = location?.state || {};
  const loadingDataValue = useSelector(loaderData);
  const { id } = useParams();
  const [cookies] = useCookies(["cuid", "t", "cid", "is_admin"]);
  const [reportShow, setReportShow] = useState(false);
  const [data, setData] = useState<any>({
    // people_overview : { profile_photo : any}
  });

  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await getData({
          endpoint: "People/customer/getPeopleProfileDetails",
          params: { people_id: id },
          token: cookies.t,
        });
        if (result) {
          setTimeout(() => {
            setLoading(false);
          });
        }
        setData(result);
      } catch (error) {
        console.log(error);
      }
      // finally {
      //   setLoading(false);
      // }
    };

    fetchData();
  }, []);

  const breadcrumbItems = [
    {
      label: data?.people_overview?.company_name,
      url: navigationType === "other" ? "other" : "company",
      id: data?.people_overview?.company_id,
    },
    {
      label: data?.people_overview?.name,
      active: true,
    },
  ];

  return (
    <div id="Profile-Container">
      {loading ? (
        <Shimmer />
      ) : (
        <div className="parent">
          <div className="person-info-container">
            <Breadcrumb items={breadcrumbItems} />
            <PeopleProfileCard
              profile={data?.people_overview?.profile_photo}
              name={data?.people_overview?.name}
            />
            <div className="d-flex gap-2 align-items-center mt-3 mb-2">
              <h3 className="h2-j mb-0">{data?.people_overview?.name}</h3>

              {data?.people_overview?.tag?.length > 0 &&
                data?.people_overview?.tag[0] !== null &&
                data?.people_overview?.tag?.map((item: any) => {
                  return (
                    <>
                      <TypeDiv data={item} color="#ffbc99" />
                    </>
                  );
                })}
            </div>
            <p className="p-grey mb-1">{data?.people_overview?.job_title}</p>

            <p
              className="p mb-1 companyNameArrow"
              onClick={() => {
                navigate(
                  `/dashboard/company/${data?.people_overview?.company_id}`
                );
              }}
            >
              {data?.people_overview?.company_name}
              <div className="rightUpArrow">
                <RightUpArrow />
              </div>
            </p>

            {cookies.cuid !== id &&
            data?.people_overview?.company_id !== cookies.cid &&
            userInfo.role === roles.manager ? (
              <></>
            ) : (
              <div className="d-flex gap-3 mt-3 mb-3">
                {(cookies.cuid === id ||
                  (data?.people_overview?.company_id === cookies.cid &&
                    userInfo?.is_admin)) && (
                  <div
                    className="btn btn-primary"
                    onClick={(e) => {
                      isSubscriptionExpired
                        ? handleUserClick(e)
                        : navigate(`/dashboard/people/edit/${id}`);
                    }}
                  >
                    Update
                  </div>
                )}

                {cookies.cuid !== id && (
                  <>
                    {data?.people_overview?.company_id === cookies.cid ? (
                      <SaveComponent
                        type="people"
                        id={data?.people_overview?.people_id}
                        saved_id={data?.people_overview?.saved_list}
                        key={data?.people_overview?.saved_list}
                        btnType="btn-outline-dark"
                      />
                    ) : (
                      <>
                        <RequestInfoForm
                          type="Request"
                          mapType="People"
                          mapId={id}
                        />
                        <SaveComponent
                          type="people"
                          id={data?.people_overview?.people_id}
                          saved_id={data?.people_overview?.saved_list}
                          key={data?.people_overview?.saved_list}
                        />
                        <CircularDropdown
                          icon={<DotsIcon />}
                          border="1px solid #1A1D1F"
                          size="50px"
                          bgColor="#F4F4F4"
                          direction="right"
                        >
                          <div
                            className="d-flex gap-3 "
                            style={{ lineHeight: "normal", cursor: "pointer" }}
                            onClick={() => setReportShow(true)}
                          >
                            <span className="error-text">Report</span>
                          </div>
                        </CircularDropdown>
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            <Data data={data} />
            {reportShow && (
              <ReportForm
                type="Report"
                mapType="People"
                isReportActive={reportShow}
                handleClose={() => setReportShow(false)}
                mapId={id}
              />
            )}
          </div>

          <div className="additional">
            <Team company_id={data?.company_id} />
          </div>
        </div>
      )}
    </div>
  );
};

export default People;
