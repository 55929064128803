import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../../../atoms/Heading";
import TabStrip from "../../../../molecules/TabStrip";
import Company from "./Tabs/Company/Company";
import People from "./Tabs/People/index";
import RoadShow from "./Tabs/Roadshow/index";
import style from "./index.module.scss";

const NewInvestorDatabase = () => {
  const [activeTab, setActiveTab] = useState("Company");
  const navigate = useNavigate();
  const { query } = useParams();

  useEffect(() => {
    if (query) {
      const updatedQuery = query.charAt(0).toUpperCase() + query.slice(1);
      setActiveTab(updatedQuery);
    }
  }, [query]);

  const dataArray = [
    {
      id: 0,
      label: "Company",
      view: <Company />,
    },
    {
      id: 1,
      label: "People",
      view: <People />,
    },
    {
      id: 2,
      label: "Roadshow",
      view: <RoadShow />,
    },
  ];

  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
    navigate(`/dashboard/investors/investordatabase/${value}`);
  };

  return (
    <div className={style.Main_Investor_Info_Container}>
      <div className={style.Investor_DataBase_Container}>
        <Heading
          type="main"
          title="Investors"
          classes={style.products_database_heading_container}
        />
      </div>

      <div className="requests-tab-div">
        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={dataArray}
        />
        <Box sx={{ width: "100%" }}>
          <Box>{_getComponent(activeTab)}</Box>
        </Box>
      </div>
    </div>
  );
};

export default NewInvestorDatabase;
