import { useEffect, useState } from "react";

const useRecaptcha = (action) => {
  const siteKey = process.env.REACT_APP_SITE_URL;
  const [recaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    const loadRecaptcha = () => {
      window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(siteKey, {
          action,
        });
        setRecaptchaToken(token);
      });
    };

    if (window.grecaptcha && window.grecaptcha.enterprise) {
      loadRecaptcha();
    } else {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
      script.async = true;
      script.onload = loadRecaptcha;
      document.body.appendChild(script);
    }
  }, [siteKey, action]);

  return recaptchaToken;
};

export default useRecaptcha;
