import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../../../../SubscriptionContext";
import DeleteRed from "../../../../Icons/DeleteRed";
import PeopleProfileCard from "../../../../atoms/PeopleProfileCard";
import NoContentCard from "../../../../molecules/NoContentCard";
import "../saved_list.scss";

const SavedPeople = ({ removeIcon, data, handleDelete, isSearched }: any) => {
  const navigate = useNavigate();

  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  return (
    <div className="saved-data-container">
      <>
        {data?.length > 0 ? (
          <>
            {data?.map((item: any) => (
              <div className="saved-item-container">
                <div
                  className="saved-item cursor-pointer"
                  key={item.people_id}
                  onClick={(e) => {
                    isSubscriptionExpired
                      ? handleUserClick(e)
                      : window.open(
                          `/dashboard/people/${item.people_id}`,
                          "_blank"
                        );
                  }}
                >
                  <PeopleProfileCard
                    profile={item?.profile_photo}
                    size="90px"
                    borderRadius="20%"
                    name={item.name}
                  />

                  <div>
                    <h4 className="h4-i semi-light mb-1">{item?.name}</h4>
                    <p className="p mb-1">{item.job_title}</p>
                    <p className="p-dark mb-1">{item.company}</p>
                    <p className="p mb-1">
                      <>
                        {item?.state}
                        {item?.state && ", "} {item?.country}
                      </>
                    </p>
                  </div>
                </div>

                <button
                  className={
                    !removeIcon ? "btn btn-outline-danger" : "manage-icon"
                  }
                  onClick={(e) => {
                    isSubscriptionExpired
                      ? handleUserClick(e)
                      : handleDelete(item.saved_list);
                  }}
                >
                  {!removeIcon ? (
                    "Remove"
                  ) : (
                    <i className="d-flex">
                      <DeleteRed className="Saved-delete-icon-container" />
                    </i>
                  )}
                </button>
              </div>
            ))}
          </>
        ) : (
          <div className="non-saved">
            {isSearched ? (
              <NoContentCard title="Sorry! No Result Found" type="Search" />
            ) : (
              <NoContentCard title="No Person Saved Yet !" type="" />
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default SavedPeople;
