import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import NoContentCard from "../../../../molecules/NoContentCard";
import UserProfileCard from "../../../../molecules/UserProfileCard";
import "./component.scss";

const CompanyTeam = ({ data }: any) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const investment = data?.teams?.investment_team || [];
  const marketing = data?.teams?.marketting_team || [];
  const operation = data?.teams?.operation_team || [];

  // Function to filter team members based on search query
  const filterTeam = (team: any[]) => {
    return team.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.job_title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  // Filtered teams
  const filteredInvestment = filterTeam(investment);
  const filteredMarketing = filterTeam(marketing);
  const filteredOperation = filterTeam(operation);

  return (
    <>
      {/* Search Input */}

      <div className="d-flex justify-content-between mb-3 align-items-center">
        <Heading type="smaller" title={"Teams"} classes="mt-2 mb-2" />
        <Search
          onSearchEmit={(item) => setSearchQuery(item)}
          placeholder="Seach By Name, Job Title"
          variant="search_md"
        />
      </div>

      {filteredInvestment.length > 0 && (
        <>
          <h3 className="h3-j mb-m">Investment Team</h3>
          <div className="company_team">
            {filteredInvestment.map((item: any) => (
              <UserProfileCard
                key={item.id}
                profileName={item.name}
                profilePosition={item.job_title}
                onClick={() => navigate(`/dashboard/people/${item.id}`)}
                profilePicture={item.profile_photo}
              />
            ))}
          </div>
        </>
      )}
      {filteredMarketing.length > 0 && (
        <>
          <h3 className="h3-j mb-m">Marketing Team</h3>
          <div className="company_team">
            {filteredMarketing.map((item: any) => (
              <UserProfileCard
                key={item.id}
                profileName={item.name}
                profilePosition={item.job_title}
                onClick={() => navigate(`/dashboard/people/${item.id}`)}
                profilePicture={item.profile_photo}
              />
            ))}
          </div>
        </>
      )}
      {filteredOperation.length > 0 && (
        <>
          <h3 className="h3-j mb-m">Operation Team</h3>
          <div className="company_team">
            {filteredOperation.map((item: any) => (
              <UserProfileCard
                key={item.id}
                profileName={item.name}
                profilePosition={item.job_title}
                onClick={() => navigate(`/dashboard/people/${item.id}`)}
                profilePicture={item.profile_photo}
              />
            ))}
          </div>
        </>
      )}

      {searchQuery &&
        filteredInvestment.length === 0 &&
        filteredMarketing.length === 0 &&
        filteredOperation.length === 0 && (
          <NoContentCard title="No Record Found" type="Search" />
        )}
    </>
  );
};

export default CompanyTeam;
