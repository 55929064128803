import debounce from "lodash/debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { getData, patchData } from "../../../../services";
import { loaderData, setLoading } from "../../../../store/slices/loaderSlice";
import RevokeIcon from "../../../Icons/Revoke";
import View from "../../../Icons/View";
import ContentContainer from "../../../atoms/ContentContainer";
import Heading from "../../../atoms/FormHeading";
import Search from "../../../atoms/Search";
import TypeDiv from "../../../atoms/TypeDiv";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import DeleteModal from "../../../molecules/Modal/DeleteModal";
import Modal from "../../../molecules/Modal/Normal";
import NoContentCard from "../../../molecules/NoContentCard";
import Pagination from "../../../molecules/Pagination";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import ContentSharingPhone from "./ContentSharingPhone/ContentSharingPhoneMobile";
import ContentSharingShimmer from "./Shimmer/ConetntSharing";
import ContentSharingPhoneShimmer from "./Shimmer/ContentSharingPhones";
import "./contentSharing.scss";
import SharedView from "./sharedView";
const ContentSharing = () => {
  const [filters, setFilters] = useState({});
  const [searchedValue, setSearchedValue] = useState(null);
  const [contentSharedData, setContentSharedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [cookies] = useCookies(["t", "cuid"]);
  const [revokebtn, setRevokebtn] = useState(false);
  const dispatch = useDispatch();
  const loadingDataValue = useSelector(loaderData);
  const [modalState, setModalState] = useState({
    isViewModalOpen: false,
    modalId: "",
    name: "",
    formData: null,
  });

  const [modalStateRevoke, setModalStateRevoke] = useState({
    isViewModalOpen: false,
    modalId: "",
    name: "",
    formData: null,
  });

  const filterData = {
    Module: ["Data Room", "Blog", "Product", "Product Videos"],
    Type: [
      "Excel",
      "Document",
      "Video",
      "Podcast",
      "Pdf",
      "Others",
      "Resource",
      "Library",
      "Product",
    ],
  };
  const screenwidth = useScreenWidth();
  const getContentSharingData = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const res = await getData({
        endpoint: "ContentSharing/getMyAllSharedContent",
        params: {
          search_query: searchedValue,
          user_id: cookies.cuid,
          page: currentPage,
          page_limit: itemsPerPage,
          start_date: filters?.startDate,
          end_date: filters?.endDate,
          modules:
            filters?.Module?.length > 0
              ? JSON.stringify(filters?.Module)
              : null,
          types:
            filters?.Type?.length > 0 ? JSON.stringify(filters?.Type) : null,
        },
        token: cookies.t,
      });

      dispatch(setLoading(false));
      setContentSharedData(res);
    } catch (error) {
      console.log(error);
    }
  }, [cookies.t, searchedValue, filters, currentPage, itemsPerPage]);

  useEffect(() => {
    getContentSharingData();
  }, [getContentSharingData]);

  const handleSearchEmit = (searchValue) => {
    setSearchedValue(searchValue);
  };

  const handleDebounceSearch = debounce(handleSearchEmit, 1000);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {searchedValue || filters?.Module || filters?.Type ? (
          <NoContentCard title="No Record Found" type="Search" />
        ) : (
          <NoContentCard title="No Content Shared" />
        )}
      </>
    );
  });

  const handleClickRevoke = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      name: `View Request Information`,
      formData: data,
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "module",
      headerName: "Module",
      flex: 0.4,
      renderCell: (params) => {
        return <div>{params.row.module}</div>;
      },
    },
    {
      field: "name",
      headerName: "Item Shared",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ textWrap: "pretty" }}>{params.row.name}</div>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.3,
      render: (params) => {
        return <div>{params.row.type}</div>;
      },
    },
    {
      field: "company_name",
      headerName: "Company Name",
      renderCell: (params) => {
        return <div>{params.row.company_name}</div>;
      },
      flex: 1,
    },
    {
      field: "created",
      headerName: "Shared Date",
      flex: 0.6,
      renderCell: (params) => {
        return <span>{moment(params.row.date).format("MMM D, YYYY")}</span>;
      },
    },

    {
      field: "expiry",
      headerName: " Expiry Date",
      renderCell: (params) => {
        return <span>{moment(params.row.expiry).format("MMM D, YYYY")}</span>;
      },
      flex: 0.6,
    },
    {
      field: "status",
      headerName: " Status",
      renderCell: (params) => {
        return (
          <TypeDiv
            data={params.row?.status}
            color={params.row?.status === "Revoked" ? "#FFB7B7" : "#B5E4CA"}
          />
        );
      },
      flex: 0.4,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              className="me-3"
              onClick={() => handleClickRevoke(params.row)}
            >
              <View />
            </span>
            {params?.row?.status === "Shared" ? (
              <span
                type="button"
                className="me-3 revoke-conatiner"
                onClick={() => {
                  handleRevokeLink(params?.row);
                }}
              >
                <RevokeIcon className="edit-video-icon" />
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  const handleRevokeLink = (data) => {
    setModalStateRevoke((prev) => ({
      ...prev,
      isViewModalOpen: true,
      modalId: data?.id,
      name: "",
      formData: data,
    }));
  };

  const handleUpdate = async () => {
    // dispatch(setLoading(true));
    try {
      const res = await patchData({
        endpoint: "ContentSharing/revokeLink",
        params: {
          link_id: modalStateRevoke?.formData?.id,
        },
      });
      if (res) {
        toast.success("Status Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        // dispatch(setLoading(false));
        getContentSharingData();
        setModalStateRevoke((prev) => ({
          ...prev,
          isViewModalOpen: false,
        }));
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An Error Occured", { duration: 1000, style: errorStyles });
      dispatch(setLoading(false));
    }
  };
  return (
    <>
      <ContentContainer type="dashboard">
        <Heading
          type="main"
          title="Shared Content"
          className="sharedConetent-bottom-margin"
        />
        <div className="mt-large sharedConetent-top-margin">
          <div className="d-flex justify-content-between aligh-items-center sharedConetent-padding-left-phone mb-2">
            <Search
              placeholder="Search by Email, Company"
              onSearchEmit={handleDebounceSearch}
              phonestyle={{ minWidth: "75vw", maxWidth: "75vw" }}
              variant="search_lg"
            />
            <FilterComponent
              headingMobile={screenwidth < 500 ? true : false}
              filterData={filterData}
              isDateRange={true}
              onFilterChange={(data) => {
                setFilters(data);
              }}
              type="right"
            />
          </div>
          {screenwidth > 900 ? (
            <>
              {loadingDataValue ? (
                <ContentSharingShimmer />
              ) : (
                <CustomDatagrid
                  rows={contentSharedData?.data || []}
                  getRowId={(row) => row.id}
                  columns={columns}
                  height={() => 80}
                  hoverColor="ffffff"
                  CustomNoRowsOverlay={CustomNoRowsOverlay}
                />
              )}
            </>
          ) : (
            <>
              {loadingDataValue ? (
                <ContentSharingPhoneShimmer />
              ) : contentSharedData?.data?.length > 0 ? (
                <ContentSharingPhone
                  data={contentSharedData?.data || []}
                  modalStateRevoke={modalStateRevoke}
                  setModalStateRevoke={setModalStateRevoke}
                />
              ) : (
                <div className="no-content">
                  {searchedValue || filters?.Module || filters?.Type ? (
                    <NoContentCard
                      title="Sorry! No Result Found"
                      type="Search"
                    />
                  ) : (
                    <NoContentCard title="No Data Found !" type="" />
                  )}
                </div>
              )}
            </>
          )}
          {contentSharedData?.total_record >= 10 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={contentSharedData?.total_record}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
          {modalState.isViewModalOpen && (
            <Modal
              id={modalState.modalId}
              isActive={modalState.isViewModalOpen}
              onClose={() =>
                setModalState((prevState) => ({
                  ...prevState,
                  isViewModalOpen: false,
                }))
              }
              title={`View Shared content`}
              width="642px"
              overflowStyle={{ overflow: "visible" }}
            >
              <SharedView
                data={modalState.formData}
                onClose={() =>
                  setModalState((prevState) => ({
                    ...prevState,
                    isViewModalOpen: false,
                  }))
                }
                // onUpdate={() => getRequestData()}
              />
            </Modal>
          )}
          {modalStateRevoke?.isViewModalOpen && (
            <DeleteModal
              isActive={modalStateRevoke?.isViewModalOpen}
              onClose={() => {
                setModalStateRevoke((prev) => ({
                  ...prev,
                  isViewModalOpen: false,
                }));
              }}
              onClick={handleUpdate}
              title="Shared Content"
              name="revoke"
              type="Revoke"
              description="Are you sure need to revoke the shared content?"
            ></DeleteModal>
          )}
        </div>
      </ContentContainer>
    </>
  );
};
export default ContentSharing;
